import React from "react";

import ingredients from "./ingredients.js";
import IngredientList from "./IngredientList.js";
import CookingSteps from "./CookingSteps.js";

import Autocomplete from "./Autocomplete.js";

import Divider from "./Divider.js";

import { ConversionToMl, recipeUnitFormat } from "./cooking.js";

import { useStickyState } from "./StickyState.js";


const IngredientBuilder = ({ onMake }) => {
  const [name, setName] = React.useState(null);
  const [amount, setAmount] = React.useState(1);
  const [unit, setUnit] = React.useState(null);
  const [prep, setPrep] = React.useState("none");

  return (
    <div
      style={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        maxWidth: "800px",
        height: "100%",
        backgroundColor: "#234",
      }}
    >
      <h3> Add Ingredient </h3>
      <div
        style={{
          display: "flex",
          marginTop: "5px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <label>
          Name
          <Autocomplete
            options={ingredients}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </label>

        <label>
          Amount
          <input
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => {
              const new_value = e.target.value;
              if (new_value.length === 0) setAmount("");
              if (new_value >= 0) setAmount(new_value);
            }}
          />
        </label>

        <label>
          Unit
          <Autocomplete
            options={Object.keys(ConversionToMl)}
            value={unit}
            onChange={(e) => {
              setUnit(e.target.value);
            }}
          />
        </label>

        <label>
          Preperation
          <select
            aria-label="preperation"
            id="prep"
            value={prep}
            onChange={(e) => setPrep(e.target.value)}
            style={{ margin: "20px" }}
          >
            <option value="none"> None </option>
            <option value="minced"> Minced </option>
            <option value="diced"> Diced </option>
            <option value="chopped"> Chopped </option>
            <option value="ground"> Ground </option>
          </select>
        </label>
      </div>

      <button
        color="primary"
        variant="contained"
        onClick={() => {
          console.log(name, amount, unit, prep);
          if (name !== null) {
            onMake(name, amount, unit, prep);
          }
        }}
      >
        Add
      </button>
    </div>
  );
};


export default function RecipeCreator() {
  const [ingredients, setIngredients] = useStickyState(
    {},
    "creator-ingredients"
  );
  const [nextId, setNextId] = useStickyState(1);

  const [steps, setSteps] = useStickyState([], "creator-steps");

  const handleDelete = (id) => {
    let { [id]: deleted, ...rest } = ingredients;

    setIngredients(rest);
  };

  const formatted_ing = recipeUnitFormat(ingredients);

  return (
    <div className="App">
      <h2 style={{ marginBottom: "10px", marginTop: "3px" }}>Recipe Creator</h2>
      <Divider style={{ marginBottom: "10px" }} />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <IngredientList
            ingredients={formatted_ing}
            deletable
            showId
            onDelete={handleDelete}
          />
          <IngredientBuilder
            onMake={(name, amount, unit, prep) => {
              setIngredients({
                ...ingredients,
                [nextId]: {
                  name,
                  amount,
                  unit,
                  prep,
                },
              });
              setNextId(nextId + 1);
            }}
          />
        </div>

        <CookingSteps
          steps={steps}
          ingredients={formatted_ing}
          enableEdit
          onChange={(val) => setSteps(val)}
        />
      </div>
    </div>
  );
}
