const ingredients = [
  "salt",
  "pepper",
  "oil",
  "flour",
  "garlic",
  "sugar",
  "water",
  "onion",
  "olive",
  "chicken",
  "juice",
  "milk",
  "lemon",
  "butter",
  "egg",
  "cheese",
  "wheat",
  "vegetable",
  "vanilla",
  "vinegar",
  "parsley",
  "honey",
  "soy",
  "wine",
  "seeds",
  "celery",
  "rice",
  "ground cinnamon",
  "tomato",
  "bread",
  "eggs",
  "onions",
  "yeast",
  "leaves",
  "broth",
  "tomatoes",
  "cream",
  "cloves",
  "thyme",
  "peeled",
  "ground ginger",
  "beans",
  "soda",
  "basil",
  "mushrooms",
  "apple",
  "parmesan",
  "yogurt",
  "stock",
  "bell",
  "oats",
  "sodium",
  "beef",
  "flakes",
  "carrot",
  "oregano",
  "chocolate",
  "ground cumin",
  "paprika",
  "sesame",
  "mustard",
  "spinach",
  "corn",
  "potatoes",
  "coconut",
  "carrots",
  "nutmeg",
  "cilantro",
  "raisins",
  "chili powder",
  "syrup",
  "peas",
  "peanut",
  "almond",
  "walnuts",
  "canned",
  "lime",
  "leaf",
  "pineapple",
  "margarine",
  "cabbage",
  "cucumber",
  "broccoli",
  "cornstarch",
  "zucchini",
  "coriander",
  "paste",
  "turkey",
  "banana",
  "almonds",
  "nuts",
  "maple",
  "cheddar",
  "cider",
  "scallions",
  "lettuce",
  "dill",
  "ghee",
  "garlic clove",
  "cayenne pepper",
  "ground turmeric",
  "tomato sauce",
  "heavy whipping cream",
  "white sugar",
  "vegatable oil",
  "olive oil",
  "curry powder",
  "chicken breast",
  "chicken thighs",
  "ground beef",
];

export default ingredients;
