import React from "react";


function durationToStr(duration) {
    if (duration >= 60000) {
        const minutes_num = Math.floor(duration / 60000);
        const minutes = minutes_num.toLocaleString(undefined, {
            style: "decimal",
            minimumSignificantDigits: 1,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });

        const seconds = Math.floor((duration - minutes_num * 60000) / 1000).toLocaleString(undefined, {
            style: "decimal",
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 2,
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).padStart(2, "0");

        return `${minutes_num}:${seconds}`

    } else {
        return (duration / 1000).toLocaleString(undefined, {
            style: "decimal",
            minimumSignificantDigits: 4,
            maximumSignificantDigits: 4,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

}

const InlineTimer = ({ minSeconds, maxSeconds }) => {
  const [running, setRunning] = React.useState(false);

  const [target, setTarget] = React.useState(null);
  const [elapsed, setElapsed] = React.useState(null);

  React.useEffect(() => {
    const start = Date.now();
    if (running) {
      setElapsed(0);
      const interval = setInterval(() => {
        const now = Date.now();
        setElapsed(now - start);

        if (now - start <= 0) {
          clearInterval(interval);
        }
      }, 300);
      return (_) => clearInterval(interval);
    }
    return;
  }, [running, minSeconds, maxSeconds]);

  const minMinutes = Math.round(minSeconds / 60.0);
  const maxMinutes = Math.round(maxSeconds / 60.0);

  const remaining = elapsed !== null ? minSeconds * 1000 - elapsed : null;

  if (maxSeconds === undefined || minMinutes === maxMinutes) {
    return <span> {minMinutes} minutes </span>;
  } else if (!running) {
    return (
      <button onClick={() => setRunning((v) => !v)} style={{paddingTop: "1px", paddingBottom: "1px"}}>
        {minMinutes} to {maxMinutes} minutes
      </button>
    );
  } else {
    return (
      <button onClick={() => setRunning((v) => !v)} style={{paddingTop: "1px", paddingBottom: "1px"}}>
        {remaining !== null ? durationToStr(remaining) : null}
      </button>
    );
  }
};

export default InlineTimer;
