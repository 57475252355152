
const Controls = ({
  scale,
  units,
  useWeight,
  onUseWeightChange,
  onScaleChange,
  onUnitChange,
}) => {
  return (
    <div
      className="noprint"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        flexWrap: "wrap"
      }}
    >
      <select
        style={{ marginLeft: "10px", marginRight: "10px" }}
        name="units"
        onChange={(e) => onUnitChange(e.target.value)}
      >
        <option value="imperial"> Imperial Volume </option>
        <option value="metric"> Metric Volume </option>
        {/* <option value="bakerspercent"> Baker's Percentages </option> */}
      </select>
      <label style={{ marginTop: "auto", marginBottom: "auto" }}>
        Use Weight
        <input
          type="checkbox"
          value={useWeight}
          onChange={(e) => {
            const target = e.target;
            const value =
              target.type === "checkbox" ? target.checked : target.value;
            onUseWeightChange(value);
          }}
        />
      </label>

      <div
        style={{
          flexGrow: 1,
          paddingLeft: "20px",
          paddingRight: "20px",
          maxWidth: "340px",
        }}
      >
        <label style={{marginTop: "4px"}}> Scale
        <input
          type="range"
          step={0.25}
          style={{marginTop: "0px"}}
          defaultValue={1.0}
          min={0.0}
          max={5.0}
          onChange={(e) => onScaleChange(e.target.value)}
          marks={[
            {
              value: 0.25,
            },
            {
              value: 0.5,
            },
            {
              value: 0.75,
            },
            {
              value: 1.0,
            },
            {
              value: 1.5,
            },
            {
              value: 2.0,
            },
            {
              value: 3.0,
            },
            {
              value: 4.0,
            },
            {
              value: 5.0,
            },
          ]}
        />
        </label>
      </div>
    </div>
  );
};

export default Controls;
