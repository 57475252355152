import { subset_sum_naive, subset_sum_greedy } from "./solver.js";

const IngredientDensity = {
  sugar: 0.8,
  flour: 0.7,
  salt: 1.2,
  //butter: 0.9,
  water: 1.0,
  vanilla: 0.86,
  "ground cinnamon": 0.53,
};

export const ConversionToSeconds = {
  hour: 3600.0,
  minute: 60.0,
  second: 1.0,
};

export const ConversionToMl = {
  drop: 0.0513429,
  smidgen: 0.115522,
  pinch: 0.231043,
  dash: 0.462086,
  saltspoon: 0.924173,
  scruple: 0.924173,
  coffeespoon: 1.84835,
  teaspoon: 4.92892,
  dessertspoon: 9.85784,
  tablespoon: 14.7868,
  "fluid ounce": 29.5735,
  wineglass: 59.1471,
  teacup: 118.294,
  cup: 236.588,
  pint: 473.176,
  quart: 946.353,
  pottle: 1892.71,
  gallon: 3785.41,
  liter: 1000.0,
  ml: 1.0,
};

const US_MeasuringCups = {
  "⅛ tsp": ConversionToMl["teaspoon"] / 8,
  "¼ tsp": ConversionToMl["teaspoon"] / 4,
  "½ tsp": ConversionToMl["teaspoon"] / 2,
  "¾ tsp": (ConversionToMl["teaspoon"] * 3) / 4,
  "1 tsp": ConversionToMl["teaspoon"],
  "½ Tbsp": ConversionToMl["tablespoon"] / 2,
  "1 Tbsp": ConversionToMl["tablespoon"],
  "¼ cup": ConversionToMl["cup"] / 4,
  "⅓ cup": ConversionToMl["cup"] / 3,
  "½ cup": ConversionToMl["cup"] / 2,
  "1 cup": ConversionToMl["cup"],
};

const US_SingleCups = ["1 cup", "1 Tbsp", "1 tsp"];

const units_to_pluralize = [
  "cup",
  "tablespoon",
  "teaspoon",
  "pint",
  "quart",
  "gallon",
];

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function amount_builder(amount, cups, max_error_frac) {
  let cup_values = Object.keys(cups).map((k) => cups[k]);
  let result = subset_sum_greedy(cup_values, amount, 0.03);

  return result.arr
    .map((x) => {
      const cup_name = getKeyByValue(cups, x.value);
      if (x.count === 1) {
        return `${cup_name}`;
      } else if (US_SingleCups.includes(cup_name)) {
        return `${x.count} ${cup_name.slice(2)}`;
      } else {
        return `${x.count} x ${getKeyByValue(cups, x.value)}`;
      }
    })
    .join(" + ");
}

export function unit_pluralize(unit_name, amount) {
  if (units_to_pluralize.includes(unit_name) && amount !== 1) {
    return unit_name + "s";
  } else {
    return unit_name;
  }
}

export function amountFormatter(name, amount, unit_str, settings = null) {
  if (unit_str === "" || ConversionToMl[unit_str] === undefined) {
    return amount.toString();
  }

  if (settings === null) {
    settings = {
      useWeight: false,
      unit: "imperial",
      minWeight: 15,
    };
  }

  const amount_ml = amount * ConversionToMl[unit_str];
  let amount_converted = amount;
  let converted_unit = unit_str;

  if (
    settings.useWeight &&
    IngredientDensity[name.toLowerCase()] !== undefined &&
    amount_ml * IngredientDensity[name.toLowerCase()] > settings.minWeight
  ) {
    amount_converted = amount_ml * IngredientDensity[name.toLowerCase()];
    converted_unit = "g";
    const amount_rounded = Number(amount_converted.toPrecision(2));
    const amount_str = amount_rounded.toString();

    return `${amount_str} ${unit_pluralize(converted_unit, amount_rounded)}`;
  } else if (settings.unit === "imperial") {
    return amount_builder(amount_ml, US_MeasuringCups, 0.02);
  } else if (settings.unit === "metric") {
    const amount_rounded = Number(amount_ml.toPrecision(2));
    const amount_str = amount_rounded.toString();

    return `${amount_str} ml`;
  }
}

export function scaleRecipe(recipe, scale) {
  let new_recipe = { ...recipe };
  new_recipe.ingredients = Object.keys(new_recipe.ingredients).reduce(
    (acc, key) => {
      acc[key] = {
        ...new_recipe.ingredients[key],
        amount: new_recipe.ingredients[key].amount * scale,
      };
      return acc;
    },
    {}
  );
  new_recipe.output = {
    ...recipe.output,
    amount: recipe.output.amount * scale,
  };

  return new_recipe;
}

function ingredientUnitFormat(ingredient, settings) {
  const formatted_amount = amountFormatter(
    ingredient.name,
    ingredient.amount,
    ingredient.unit,
    settings
  );

  return { ...ingredient, displayAmount: formatted_amount };
}

export function recipeUnitFormat(ingredients, settings) {
  let new_ingredients = Object.keys(ingredients).reduce((acc, key) => {
    acc[key] = ingredientUnitFormat(ingredients[key], settings);
    return acc;
  }, {});

  return new_ingredients;
}

function timerUnitFormat(timer, settings) {
  const convert_factor = ConversionToSeconds[timer.unit];
  return {
    ...timer, minSeconds: timer.min * convert_factor, maxSeconds: timer.max * convert_factor
  };
}

function timersUnitFormat(timers, settings) {
  return Object.keys(timers).reduce((acc, key) => {
    acc[key] = timerUnitFormat(timers[key], settings);
    return acc;
  }, {});
}

export function preprocessRecipe(recipe, settings) {
  if (recipe === null || recipe === undefined) return {};
  let new_recipe = scaleRecipe(recipe, settings.scale);

  new_recipe.ingredients = recipeUnitFormat(new_recipe.ingredients, settings);
  new_recipe.timers = timersUnitFormat(new_recipe.timers, settings);

  return new_recipe;
}
