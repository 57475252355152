import React from "react";

const LandingPage = () => {
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <div style={{ maxWidth: "800px", textAlign: "left" }}>
        <h1> Dynamic Recipes</h1>
        <p> These recipes can scale to your needs and adapt to your tastes. 
        With dynamic recipes you can: </p>
        <ul>
          <li> Scale recipes to any number of target servings </li>
          <li> Change between metric and imperial units </li>
          <li> Change between weight and volume measurements </li>
          <li> Automatically set timers inside the recipe</li>
        </ul>

        <p> Here are some examples to get you started: </p>
        <ul>
          <li>
            <a href="#/view/snickerdoodles"> Snickerdoodles </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LandingPage;
