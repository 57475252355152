import React from "react";

import { $getRoot, $getSelection } from "lexical";

import Divider from "./Divider";
import InlineTimer from "./InlineTimer";

import LexicalComposer from "@lexical/react/LexicalComposer";
import LexicalPlainTextPlugin from "@lexical/react/LexicalPlainTextPlugin";
import LexicalContentEditable from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalOnChangePlugin from "@lexical/react/LexicalOnChangePlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { useStickyState } from "./StickyState";

const theme = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
};
function MyCustomAutoFocusPlugin() {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    // Focus the editor when the effect fires!
    editor.focus();
  }, [editor]);

  return null;
}

function onError(error) {
  console.error(error);
}

const Placeholder = ({children}) => {
  return <div className="editor-placeholder"> {children} </div>
}

const Tooltip = ({
  children,
  hint,
  enterTouchDelay,
  disableFocusListener,
  arrow,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  let tip = null;
  if (true) {
    tip = <span className="tooltiptext"> {hint} </span>;
  }

  return (
    <div
      className="tooltip"
      onClick={() => setIsOpen((x) => !x)}
      style={{ display: "inline" }}
    >
      {children} {tip}
    </div>
  );
};

const InlineIngredient = ({ name, amount }) => {
  return (
    <Tooltip hint={amount} enterTouchDelay={150}>
      <u>{name.toLowerCase()}</u>
    </Tooltip>
  );
};

const StepCreator = ({ onDone = () => {} }) => {
  const [text, setText] = React.useState(() => {
    const stickyValue = window.localStorage.getItem("text-recipe");
    const parsed = stickyValue !== null ? JSON.parse(stickyValue) : null;

    return "";
  });

  function onChange(editorState) {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      const root = $getRoot();
      const selection = $getSelection();

      console.log(root, selection);
    });
  }

  const initialConfig = {
    theme,
    onError,
  };

  React.useEffect(() => {
    window.localStorage.setItem(
      "text-recipe",
      JSON.stringify({
        text: "",
      })
    );
  }, []);

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div
        className="editor-container"
        style={{
          width: "95%",
          backgroundColor: "#21212a",
          minWidth: "0.5vw",
          minHeight: "300px",
        }}
      >
        <LexicalPlainTextPlugin
          contentEditable={
            <LexicalContentEditable className="editor-input" />
          }
          placeholder={<Placeholder> Enter your recipe here! </Placeholder>}
        />
        <LexicalOnChangePlugin onChange={onChange} />
        <HistoryPlugin />
        <MyCustomAutoFocusPlugin />
      </div>
    </LexicalComposer>
  );
};

const StepElements = ({ instructions, ingredients, timers }) => {
  const result = instructions.match(/#(\w)(\d*)\D/);

  if (result === null) {
    return <>{instructions}</>;
  } else {
    const type = result[1];
    if (type === "I") {
      const ingredient = ingredients[`${result[1]}${result[2]}`];
      return (
        <>
          {instructions.slice(0, result.index)}
          <InlineIngredient
            name={ingredient.name}
            amount={ingredient.displayAmount}
          />
          <StepElements
            instructions={instructions.slice(
              result.index + result[0].length - 1
            )}
            ingredients={ingredients}
            timers={timers}
          />
        </>
      );
    } else if (type === "T") {
      const timer = timers[`${result[1]}${result[2]}`];
      return (
        <>
          {instructions.slice(0, result.index)}
          <InlineTimer
            minSeconds={timer.minSeconds}
            maxSeconds={timer.maxSeconds}
          />
          <StepElements
            instructions={instructions.slice(
              result.index + result[0].length - 1
            )}
            ingredients={ingredients}
            timers={timers}
          />
        </>
      );
    }
  }
};

const Step = ({ instructions, index, ingredients, timers }) => {
  return (
    <div style={{ display: "flex" }}>
      <p align="justify" style={{ margin: "3px" }}>
        {index}.
      </p>
      <div align="left" style={{ margin: "3px", display: "inline" }}>
        <StepElements
          instructions={instructions}
          ingredients={ingredients}
          timers={timers}
        />
      </div>
    </div>
  );
};

const CookingSteps = ({
  steps = [],
  ingredients = {},
  timers = {},
  enableEdit = false,
  onChange = () => {},
}) => {
  const step_elems = steps.map((x, idx) => (
    <Step
      instructions={x}
      index={idx + 1}
      key={idx}
      ingredients={ingredients}
      timers={timers}
    />
  ));

  const step_adder = (
    <StepCreator onDone={(text) => onChange([...steps, text])} />
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "10px",
        maxWidth: "1200px",
        minWidth: "300px",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        }}
      >
        <h3> Instructions </h3>
      </div>
      <Divider />
      {step_elems}
      {enableEdit ? step_adder : null}
    </div>
  );
};

export default CookingSteps;
