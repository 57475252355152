export function subset_sum_naive(amounts, target, eps, cached = {}) {
  if (amounts.length === 0)
    return target <= eps ? [{ arr: [], score: 0, total: 0 }] : [];
  if (target < eps) {
    return [{ arr: [], score: 0, total: 0 }];
  }

  let results = [];

  let best_score = 50;

  for (let i = amounts.length - 1; i >= 0; i--) {
    let value = amounts[i];
    let other_amounts = amounts.slice(i + 1);
    if (value < target + eps) {
      let max_divisor = Math.min(Math.floor(target / value), best_score);
      //console.log(value, target, max_divisor);
      for (let d = 1; d <= max_divisor; ++d) {
        let subtarget = target - d * value;

        let result = cached[subtarget];
        if (result === undefined) {
          result = subset_sum_naive(other_amounts, subtarget, eps, cached);

          if (result.length) cached[subtarget] = [result[0]];
        }

        if (result.length !== 0) {
          for (let ridx = 0; ridx < result.length; ++ridx) {
            let tmp = {
              arr: [{ count: d, value: value }, ...result[ridx].arr],
              score: d + result[ridx].score,
              total: d * value + result[ridx].total,
            };

            if (best_score > tmp.score) {
              best_score = tmp.score;
            }

            results.push(tmp);
          }
        }
      }
    }
  }

  results.sort((a, b) => a.score - b.score);
  return results.slice(0, 1);
}

export function subset_sum_greedy(amounts, target, eps) {
  let max_error = target * eps;
  amounts.sort((a, b) => b - a);
  let result = {
    score: 0,
    total: 0,
    arr: [],
  };
  let remaining_target = target;
  for (let i = 0; i < amounts.length; i++) {
    let value = amounts[i];
    if (value < remaining_target + max_error) {
      let max_divisor = Math.floor((remaining_target + max_error) / value);
      result.arr.push({ count: max_divisor, value: value });
      result.total += max_divisor * value;
      result.score += 1;

      remaining_target -= max_divisor * value;

      if (remaining_target < max_error) {
        return result;
      }
    }
  }

  return result;
}
