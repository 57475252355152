import React from "react";

import Divider from "./Divider";

import { MdDelete } from "react-icons/md";

function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const Ingredient = ({
  id,
  name,
  amount,
  deletable = false,
  checkable = false,
  showId = false,
  onDelete = () => {},
}) => {
  const deleteButton = (
    <button aria-label="delete" onClick={onDelete} style={{marginLeft: "15px", borderRadius: "40px"}}>
      <MdDelete />
    </button>
  );

  const checkbox = <input type="checkbox" color="primary" />;

  const id_elem = (
    <b style={{ marginRight: "0.3em", color: "#7a3" }}>{`#I${id}`}</b>
  );

  return (
    <li
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {showId ? id_elem : null}
      <p style={{ textAlign: "left" }}>{capitalize(name)}</p>
      <span
        style={{
          display: "inline-flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <p style={{ textAlign: "right", marginLeft: "10px" }}>{amount}</p>
        {deletable ? deleteButton : null}
        {checkable ? checkbox : null}
      </span>
    </li>
  );
};

const IngredientList = ({
  ingredients = {},
  deletable = false,
  showId = false,
  onDelete = (val) => {},
}) => {
  const ingredient_elems = Object.keys(ingredients).map((key, index) => {
    const x = ingredients[key];

    return (
      <div key={index} >
        <Ingredient
          key={index * 2}
          id={key}
          name={x.name}
          amount={x.displayAmount}
          deletable={deletable}
          showId={showId}
          onDelete={() => onDelete(key)}
        />
        <Divider key={index * 2 + 1} />
      </div>
    );
  });

  return (
    <div style={{ maxWidth: "500px", marginRight: "25px", marginLeft: "2px", flexGrow: 1 }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: "space-between",
          minWidth: "250px"
        }}
      >
        <h3> Ingredients </h3>
      </div>
      <Divider />
      <ul style={{ paddingLeft: "10px" }}>{ingredient_elems}</ul>
    </div>
  );
};

export default IngredientList;
