import React from "react";

import { preprocessRecipe } from "./cooking.js";

import Controls from "./Controls.js";
import IngredientList from "./IngredientList.js";
import CookingSteps from "./CookingSteps.js";

const Recipe = ({ recipe }) => {
  const [scale, setScale] = React.useState(1.0);
  const [useWeight, setUseWeight] = React.useState(false);
  const [unit, onUnitChange] = React.useState("imperial");

  const settings = {
    scale,
    useWeight,
    unit,
    minWeight: 15,
  };

  const displayRecipe = preprocessRecipe(recipe, settings);
  return (
    <div style={{ marginLeft: "5px", marginRight: "5px" }}>
      <h2> {recipe.title} </h2>
      <h5 color="textSecondary">{recipe.description}.</h5>
      <h4 color="textSecondary">
        Makes {displayRecipe.output.amount} {displayRecipe.output.name}.
      </h4>
      <Controls
        scale={scale}
        onScaleChange={setScale}
        useWeight={useWeight}
        onUseWeightChange={setUseWeight}
        onUnitChange={onUnitChange}
      />
      <div
        style={{
          display: "flex",
          overflowX: "hidden",
          flexWrap: "wrap",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <IngredientList ingredients={displayRecipe.ingredients} />
        <CookingSteps
          steps={displayRecipe.instructions}
          ingredients={displayRecipe.ingredients}
          timers={displayRecipe.timers}
        />
      </div>
    </div>
  );
};

export default Recipe;
