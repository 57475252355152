import React from "react";
import Recipe from "./Recipe.js";
import { useParams } from "react-router-dom";

export default function RecipeLoader() {

  const params = useParams();
  const recipename = params.recipename;

  const [recipe, setRecipe] = React.useState(null);
  React.useEffect(() => {
      const path = recipename;
      fetch(`/recipes/${path}.json`)
        .then((resp) => resp.json())
        .then((data) => {
          setRecipe(data);
        });
  }, [recipename]);
  return recipe !== null ? <Recipe recipe={recipe} /> : null;
}
