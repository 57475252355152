import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";

import RecipeLoader from "./RecipeLoader.js";
import RecipeCreator from "./RecipeCreator.js";
import LandingPage from "./LandingPage";

function App() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
      }}
    >

      <div
        style={{
          margin: "0px",
          display: "flex",
          justifyContent: "space-around",
        }}

        className="noprint"
      >
        <Link to="/" style={{ padding: "20px" }}>
          Home
        </Link>
        <Link to="/create" style={{ padding: "20px" }} >
          Create
        </Link>
      </div>
      <div
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          padding: "0 10px",
        }}
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="create" element={<RecipeCreator />} />
          <Route path="view/:recipename" element={<RecipeLoader />} />
        </Routes>
      </div>

    </div>
  );
}

export default App;
